import React from 'react';
import Slider from 'react-slick';

const Tech = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        centerPadding: '15px',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            }
        ]
    };
    return (
        <div style={styles.tech}>
            <Slider {...settings}>
                <div style={styles.item}>
                    <img style={styles.image} src='./images/html.png' alt='html 5' />
                </div>
                <div style={styles.item}>
                    <img style={styles.image} src='./images/css-3.png' alt='css 3' />
                </div>
                <div style={styles.item}>
                    <img style={styles.image} src='./images/lettre-c.png' alt='Langage C' />
                </div>
                <div style={styles.item}>
                    <img style={styles.image} src='./images/python.png' alt='Python' />
                </div>
                <div style={styles.item}>
                    <img style={styles.image} src='./images/java.png' alt='Java' />
                </div>
            </Slider>
        </div>
    );
};

const styles = ({

    tech: {
    },
    item: {
        display: "flex",
        justifyContent: "space-around",
    },
    image: {
        width: "40%",
        margin: "0 auto",
    }
})

export default Tech;
