import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Service = ({ darkMode }) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const styles = ({
        head: {
            border: isMobile ? '' : `1px solid #707070`,
            boxShadow: isMobile ? '' : darkMode ? '0 0 8px #fff' : '',
            borderRadius: "30px 30px 0 0",
            paddingBottom: isMobile ? '20px' : '',
        },
        para: {
            width: isMobile ? "100%" : isTabletOrMobile ? "100%" : "50%",
            padding: "4% 0 5% 8%",
            color: darkMode ? '#fff' : '',
            marginBottom: isMobile ? '50px' : isTabletOrMobile ? '50px' : '',
        },
        carou: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            alignContent: "center",
            width: "95%",
            margin: "-50px auto 0 auto",
        },
        item: {
            border: darkMode ? "2px solid #fff" : "1px solid #707070",
            flex: isMobile ? "1 1 70%" : isTabletOrMobile ? "1 1 80%" : "0 0 21%",
            width: isMobile ? '70vw' : isTabletOrMobile ? '70vw' : '',
            padding: isTabletOrMobile ? "25px" : "15px",
            background: darkMode ? "#212529" : "#fff",
            boxShadow: darkMode ? "-15px 15px 5px #222" : "-15px 15px 5px #D6D6D6",
            marginBottom: "20px",
            borderRadius: "20px",
        },
        img: {
            display: "flex",
            justifyContent: "space-around",
            alignContent: "center",
            alignItems: "center",
        },
        image: {
            width: "30%",
        },
        title: {
            color: darkMode ? '#fff' : '',
            paddingLeft: '10px',
        },
        para2: {
            paddingTop: "15px",
            fontSize: "0.8em",
            color: darkMode ? '#fff' : '',
        }
    })

    return (
        <div style={styles.service}>
            <div style={styles.head}>
                <p style={styles.para}>Chez ITConceptor Agency, nous offrons une gamme de services conçus pour répondre aux besoins évolutifs de nos clients, notamment :</p>
            </div>
            <div style={styles.carou}>
                <div style={styles.item}>
                    <div style={styles.img}>
                        <img style={styles.image} src={darkMode ? './images/codage_light.png' : './images/codage.png'} alt='Code' />
                        <h3 style={styles.title}>Web Developer</h3>
                    </div>
                    <div style={styles.text}>
                        <p style={styles.para2}>Nous développons des sites web sur mesure, optimisés pour la performance, la sécurité et l'expérience utilisateur afin de répondre aux besoins des entreprises modernes.</p>
                    </div>
                </div>
                <div style={styles.item}>
                    <div style={styles.img}>
                        <img style={styles.image} src={darkMode ? './images/mobile_light.png' : './images/mobile.png'} alt='Codage Mobile' />
                        <h3 style={styles.title}>Mobile Developer</h3>
                    </div>
                    <div style={styles.text}>
                        <p style={styles.para2}>Nous concevons des applications mobiles innovantes, créatives et fonctionnelles pour iOS et Android, en prenant en compte l'expérience utilisateur et les dernières technologies.</p>
                    </div>
                </div>
                <div style={styles.item}>
                    <div style={styles.img}>
                        <img style={styles.image} src={darkMode ? './images/seo_light.png' : './images/seo.png'} alt='SEO' />
                        <h3 style={styles.title}>Web SEO Manager</h3>
                    </div>
                    <div style={styles.text}>
                        <p style={styles.para2}>Nous optimisons la visibilité de votre site web grâce à des techniques avancées de référencement, vous aidant ainsi à attirer un public ciblé et à augmenter votre trafic organique.</p>
                    </div>
                </div>
                <div style={styles.item}>
                    <div style={styles.img}>
                        <img style={styles.image} src={darkMode ? './images/computer_light.png' : './images/computer.png'} alt='Ordinateur' />
                        <h3 style={styles.title}>Computer Training</h3>
                    </div>
                    <div style={styles.text}>
                        <p style={styles.para2}>Nous proposons des formations informatiques de tous niveaux, afin de vous permettre de maîtriser les outils numériques et les technologies nécessaires pour réussir dans le monde moderne.</p>
                    </div>
                </div>
                <div style={styles.item}>
                    <div style={styles.img}>
                        <img style={styles.image} src={darkMode ? './images/ux_light.png' : './images/ux.png'} alt='UI/UX' />
                        <h3 style={styles.title}>Designer</h3>
                    </div>
                    <div style={styles.text}>
                        <p style={styles.para2}>Nos designers UI/UX créent des interfaces intuitives et attractives qui offrent une expérience utilisateur fluide et engageante, pour vos applications et vos sites web.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
