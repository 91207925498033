import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Barre2 = (props) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const styles = ({
    barre2: {
      display: "flex",
      margin: "20px auto",
      justifyContent: "space-between",
      alignItems: 'center',
    },
    image: {
      width: props.data === 'methode' ? (isMobile ? "60%" :  isTabletOrMobile ? "70%" : "75%") : (isMobile ? "40%" : isTabletOrMobile ? "60%" : "75%"),
    },
    title: {
      width: "25%",
      texAlign: 'center',
      textTransform: "uppercase",
      color: "#DBA734",
    }
  })

  return (
    <div style={styles.barre2} id={props.data}>
      <h1 style={styles.title}>{props.data}</h1>
      <img style={styles.image} src={
        props.data === 'methode'
          ? (isMobile ? './images/barre4.png' : './images/barre2.png')
          : (isMobile ? './images/barre3.png' : './images/barre2.png')
      } alt='barre' />
    </div>
  );
};

export default Barre2;
