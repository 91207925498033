import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './components/Home';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Barre from './components/Barre';
import About from './components/About';
import Barre2 from './components/Barre2';
import Tech from './components/Tech';
import Method from './components/Method';
import News from './components/News';
import Mode from './components/Mode';
import Contact from './components/Contact';
import Team from './components/Team';
import Service from './components/Service';
import Project from './components/Project';

const App = () => {

    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const divClass = darkMode ? 'body bg-dark ' : 'body bg-none';

    return (
        <Router>
            <div className={divClass} >
                <div className='content'>
                    <Navbar darkMode={darkMode} />
                    <Home darkMode={darkMode} />
                    <Barre data={"about"} />
                    <About darkMode={darkMode} />
                    <Barre data={"teams"} />
                    <Team darkMode={darkMode} />
                    <Barre data={"services"} />
                    <Service darkMode={darkMode} />
                    {/* <Barre data={"projects"} /> */}
                    {/* <Project darkMode={darkMode} /> */}
                    <Barre2 data={"technologie"} />
                    <Tech />
                    <Barre2 data={"methode"} />
                    <Method darkMode={darkMode} />
                    {/* <Barre data={"news"} /> */}
                    {/* <News darkMode={darkMode} /> */}
                    <Barre data={"contact"} />
                    <Contact darkMode={darkMode} />

                    <Mode darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                </div>
                <Footer darkMode={darkMode} />
            </div>
        </Router>
    );
};

export default App;
