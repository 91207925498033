import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Barre = (props) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const styles = ({
        barre: {
            display: isMobile ? "flex-wrap" : "flex",
            marginBottom: "20px",
            justifyContent: "space-between",
        },
        inverse: {
            transform: "scaleX(-1)",
            width: isMobile ? "100%" : isTabletOrMobile ? "30%" : "40%",
        },
        image: {
            width: isMobile ? "100%" : isTabletOrMobile ? "30%" : "40%",
        },
        title: {
            textTransform: "uppercase",
            color: "#DBA734",
            width: isMobile ? "100%" : isTabletOrMobile ? "30%" : "40%",
            textAlign: 'center',
        }
    })

    return (
        <div style={styles.barre} id={props.data}>
            <img style={styles.inverse} src='./images/barre.png' alt='barre' />
            <h1 style={styles.title}>{props.data}</h1>
            <img style={styles.image} src='./images/barre.png' alt='barre' />
        </div>
    );
};

export default Barre;
