import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Method = ({ darkMode }) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const scrollTo = (id) => {
        const element = document.querySelector(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 2,
                behavior: 'smooth',
            });
        }
    };

    const styles = ({
        method: {
            display: "flex",
            justifyContent: 'space-around',
            alignItems: "center",
            flexWrap: isMobile || isTabletOrMobile ? 'wrap' : '',
            flexDirection: isMobile || isTabletOrMobile ? 'column-reverse' : '',
        },
        div: {
            width: isMobile || isTabletOrMobile ? "100%" : "45%",
            marginBottom: isMobile || isTabletOrMobile ? '10px' : '',
        },
        text: {
            width: isMobile || isTabletOrMobile ? "100%" : "50%",
            margin: isMobile || isTabletOrMobile ? '-30px 0 20px' : '',
            padding: isMobile || isTabletOrMobile ? '20px' : '',
        },
        para: {
            fontSize: "0.8em",
            color: darkMode ? '#fff' : '#000',
        },
        button: {
            backgroundColor: darkMode ? "#212529" : "white",
            border: `1px solid ${darkMode ? "#FFF" : "#707070"}`,
            borderLeft: `2px solid ${darkMode ? "#FFF" : "#707070"}`,
            borderRight: `2px solid ${darkMode ? "#FFF" : "#707070"}`,
            color: darkMode ? "#FFF" : "#000",
            boxShadow: darkMode ? "-5px 5px 3px #222" : "-5px 5px 3px #D6D6D6",
            fontSize: isMobile ? "0.8em" : isTabletOrMobile ? "0.9em" : "1em",
            marginTop: isMobile ? '10px' : '30px',
        },
        image: {
            width: "100%",
        }
    })

    return (
        <div style={styles.method}>
            <div style={styles.text}>
                <p style={styles.para}>Nous utilisons la méthode Agile pour gérer nos projets de manière flexible et itérative, en favorisant une collaboration continue avec nos clients. Grâce à des cycles courts, nous sommes en mesure d'ajuster nos priorités et d'intégrer des retours en temps réel, garantissant ainsi des livraisons fréquentes de qualité. Cette approche permet une transparence totale et une adaptation rapide aux besoins changeants du projet, assurant la satisfaction du client tout au long du processus. La méthode Agile nous permet d’être réactifs et de produire des solutions qui répondent aux attentes.</p>
                <button style={styles.button} onClick={(e) => {
                    e.preventDefault();
                    scrollTo('#contact');
                }}>Contact</button>
            </div>
            <div style={styles.div}>
                <img style={styles.image} src='./images/AGILE.png' alt='Methode AGILE' />
            </div>
        </div>
    );
};

export default Method;
