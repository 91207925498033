import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import 'bootstrap/dist/css/bootstrap.min.css';

const Navbar = ({ darkMode }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const changeStyle = (e) => {
        e.target.classList.toggle('active');
    };

    const navBarClasses = darkMode ? 'navbar navbar-expand-lg navbar-dark bg-dark' : 'navbar navbar-expand-lg navbar-light bg-none';

    const scrollTo = (id) => {
        const element = document.querySelector(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 2,
                behavior: 'smooth',
            });
        }
    };

    return (
        <nav className={navBarClasses}>
            <div className="container-fluid">
                <a className="navbar-brand ps-4" href="/">
                    <img
                        src={darkMode ? './images/logo-light.png' : './images/logo-light2.png'}
                        alt="logo ITCA"
                        // width="100"
                        height="80"
                        className="d-inline-block align-top"
                    />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setMenuOpen(!menuOpen)}
                    aria-controls="navbarNav"
                    aria-expanded={menuOpen}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse justify-content-center ${menuOpen ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link " href="/" onMouseEnter={changeStyle} onMouseOut={changeStyle} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#home');
                            }}>Accueil</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " href="/" onMouseEnter={changeStyle} onMouseOut={changeStyle} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#about');
                            }}>A Propos</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " href="/" onMouseEnter={changeStyle} onMouseOut={changeStyle} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#teams');
                            }}>Equipes</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " href="/" onMouseEnter={changeStyle} onMouseOut={changeStyle} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#services');
                            }}>Services</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link " href="/" onMouseEnter={changeStyle} onMouseOut={changeStyle} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#projects');
                            }}>Projets</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link " href="/" onMouseEnter={changeStyle} onMouseOut={changeStyle} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#technologie');
                            }}>Technologie</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link " href="/" onMouseEnter={changeStyle} onMouseOut={changeStyle} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#news');
                            }}>Actualités</a>
                        </li> */}
                        <li className="nav-item ">
                            <a className="nav-link" href="/" onMouseEnter={changeStyle} onMouseOut={changeStyle} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#contact');
                            }}>Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
