import React from 'react';
import { useMediaQuery } from 'react-responsive';

const About = ({ darkMode }) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const scrollTo = (id) => {
        const element = document.querySelector(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 2,
                behavior: 'smooth',
            });
        }
    };

    const styles = ({
        about: {
            display: isMobile ? "flex-wrap" :  "flex",
            marginBottom: "20px",
        },
        text: {
            width: isMobile? "100%" : "50%",
            padding: isMobile ? " 0 20px" : "",
        },
        img: {
            width: isMobile? "100%" : "50%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
        },
        image: {
            width: "60%",
            margin: "20px",
        },
        para: {
            margin: "50px auto",
            fontSize: "0.8em",
            textAlign: "justify",
            color: darkMode ? '#fff' : '#000',
            lineHeight: isTabletOrMobile ? "" : 1.5
        },
        but: {
            width: "100%",
            margin: "0 auto",
        },
        button: {
            backgroundColor: darkMode ? "#212529" : "white",
            border: `1px solid ${darkMode ? "#FFF" : "#707070"}`,
            borderLeft: `2px solid ${darkMode ? "#FFF" : "#707070"}`,
            borderRight: `2px solid ${darkMode ? "#FFF" : "#707070"}`,
            color: darkMode ? "#FFF" : "#000",
            boxShadow: darkMode ? "-5px 5px 3px #222" : "-5px 5px 3px #D6D6D6",
            fontSize: isMobile ? "0.8em" : isTabletOrMobile ? "0.9em" : "1em",
        }
    })

    return (
        <div style={styles.about}>
            <div style={styles.img}>
                <img src={ darkMode ? './images/logo-light.png' : './images/logo-light2.png'} alt='logo ITCA' style={styles.image} />
            </div>
            <div style={styles.text}>
                <p style={styles.para}>Chez ITConceptor Agency, nous sommes une Entreprise de Services Numériques (ESN) basée à Madagascar, dévouée à répondre aux besoins de nos clients avec une expertise reconnue dans plusieurs langages et frameworks de programmation.
                Nous sommes constamment à l'affût des dernières tendances et technologies dans le domaine du numérique. Nous investissons dans la formation continue de notre équipe pour nous assurer que nous sommes toujours à la pointe de l'innovation. En travaillant avec nous, vous pouvez être sûr que vous bénéficierez des dernières avancées technologiques.
                </p>
                <div style={styles.but}>
                    <button style={styles.button} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#contact');
                            }}>Contact</button>
                </div>
            </div>
        </div>
    );
};

export default About;
