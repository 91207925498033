import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Mode = ({ darkMode, toggleDarkMode }) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const styles = ({
        mode: {
            width: "35px",
            height: "30px",
            position: "fixed",
            top: "40vh",
            right: 0,
            border: "0.5px solid #707070",
            borderRight: "none",
            borderRadius: "20px 0 0 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            cursor: "pointer",
            backgroundColor: darkMode ? 'rgb(33, 37, 41)' : '#fff',
        },
        image: {
            width: isMobile ? "30px" : "20px",
            height: isMobile ? "30px" : "20px",
            padding: "5px",
        }
    })

    return (
        <div style={styles.mode} onClick={toggleDarkMode}>
            <img style={styles.image} src={darkMode ? './images/soleil_light.png' : './images/lunes.png'} alt='dark' />
        </div>
    );
};

export default Mode;
