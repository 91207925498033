import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from '../images/workers/rakotoarisoa_andriamparany.jpg'
import image2 from '../images/workers/tsilavina_nomenjanahary.jpg'

const Team = ({ darkMode }) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        centerPadding: '15px',
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            }
        ]
    }

    const styles = ({
        team: {
            marginBottom: '20px',
        },
        items: {
            border: '2px solid black',
        },
        item: {
            filter: darkMode
                ? 'drop-shadow(-15px 10px 3px transparent) drop-shadow(5px 5px 3px transparent)'
                : 'drop-shadow(-15px 10px 3px #D6D6D6) drop-shadow(0px -5px 3px #D6D6D6)',
            marginTop: '15px',
            marginBottom: '15px',
        },
        clip: {
            // width: '22vw',
            // height: '350px',
            border: '1px solid black',
            backgroundColor: '#fff',
            clipPath: 'polygon(50% 31%, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 0)',
            margin: '0 12%',
            padding: '40% 20px 20px',
        },
        image: {
            position: 'absolute',
            top: 0,
            left: '30%',
            zIndex: 999,
            width: '40%',
            borderRadius: '50%',
            border: ' 1px solid black',
        },
        but: {
            width: isMobile ? "200px" : isTabletOrMobile ? "250px" : "300px",
            margin: "5px 0",
        },
        button: {
            backgroundColor: "white",
            border: `1px solid #707070`,
            color: "#000",
            boxShadow: "-5px 5px 3px #D6D6D6",
            fontSize: isMobile ? "0.8em" : isTabletOrMobile ? "0.9em" : "1em",
            // width: `50px`,
        },
    })

    const scrollTo = (id) => {
        const element = document.querySelector(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 2,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div style={styles.team}>
            <Slider {...settings}>
                <div style={styles.items}>
                    <div style={styles.item}>
                        <img style={styles.image} src={image1} alt='Andriamparany RAKOTOARISOA' />
                        <div style={styles.clip}>
                            <h3 style={styles.title}>Andriamparany RAKOTOARISOA</h3>
                            <p style={styles.para}>Manager ITConceptor Agency</p>
                            <div style={styles.but}>
                                <button style={styles.button} onClick={(e) => {
                                    e.preventDefault();
                                    scrollTo('#contact');
                                }}>Plus +</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.items}>
                    <div style={styles.item}>
                        <img style={styles.image} src={image2} alt='Tsilavina NOMENJANAHARY' />
                        <div style={styles.clip}>
                            <h3 style={styles.title}>Tsilavina NOMENJANAHARY</h3>
                            <p style={styles.para}>Developer Back-End</p>
                            <div style={styles.but}>
                                <button style={styles.button} onClick={(e) => {
                                    e.preventDefault();
                                    scrollTo('#contact');
                                }}>Plus +</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.items}>
                    <div style={styles.item}>
                        <img style={styles.image} src='./images/user.png' alt='nothing' />
                        <div style={styles.clip}>
                            <h3 style={styles.title}>Manoa <br /> ROBEL</h3>
                            <p style={styles.para}>Designer / Developer Front-End</p>
                            <div style={styles.but}>
                                <button style={styles.button} onClick={(e) => {
                                    e.preventDefault();
                                    scrollTo('#contact');
                                }}>Plus +</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default Team;
