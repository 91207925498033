import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';

const Home = ({ darkMode }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const styles = {
        home: {
            margin: "50px auto 80px",
            width: "100%",
            backgroundColor: darkMode ? 'transparent' : '#fff',
            color: darkMode ? '#fff' : '#000'
        },
        title: {
            textShadow: darkMode ? "0px 0px 6px #fff" : "-10px 10px 3px #D6D6D6",
            color: darkMode ? "#707070" : "#707070",
            fontSize: isMobile ? "30px" : isTabletOrMobile ? "40px" : "50px",
            textAlign: "center",
        },
        para: {
            width: isMobile ? "200px" : isTabletOrMobile ? "250px" : "500px",
            margin: "20px auto",
            fontSize: isMobile ? "0.6em" : isTabletOrMobile ? "0.7em" : "0.8em",
            textAlign: "center",
            color: darkMode ? "#fff" : "#000",
        },
        but: {
            width: isMobile ? "200px" : isTabletOrMobile ? "250px" : "500px",
            margin: "5px auto",
        },
        button: {
            backgroundColor: darkMode ? "#212529" : "white",
            border: `1px solid ${darkMode ? "#FFF" : "#707070"}`,
            borderLeft: `2px solid ${darkMode ? "#FFF" : "#707070"}`,
            borderRight: `2px solid ${darkMode ? "#FFF" : "#707070"}`,
            color: darkMode ? "#FFF" : "#000",
            boxShadow: darkMode ? "-5px 5px 3px #222" : "-5px 5px 3px #D6D6D6",
            fontSize: isMobile ? "0.8em" : isTabletOrMobile ? "0.9em" : "1em",
            width: `50px`,
        },
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const scrollTo = (id) => {
        const element = document.querySelector(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 2,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div style={styles.home} id='home'>
            <Slider {...settings}>
                <div>
                    <h1 style={styles.title}>IT Conceptor <br /> Agency</h1>
                    <p style={styles.para}>Nous sommes une Entreprise de Services Numériques (ESN) basée à Madagascar, dévouée à répondre aux besoins de nos clients avec une expertise reconnue dans plusieurs langages et frameworks de programmation.</p>
                    <div style={styles.but}>
                        <button style={styles.button} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#contact');
                            }}>Plus</button>
                    </div>
                </div>
                <div>
                    <h1 style={styles.title}>IT Conceptor <br /> Agency</h1>
                    <p style={styles.para}>Contactez-nous dès aujourd'hui pour en savoir plus sur nos services et comment nous pouvons vous aider à réaliser vos ambitions numériques.</p>
                    <div style={styles.but}>
                        <button style={styles.button} onClick={(e) => {
                                e.preventDefault();
                                scrollTo('#contact');
                            }}>Plus</button>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default Home;
