import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Contact = ({ darkMode }) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [types, setTypes] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const mailtoLink = `mailto:contact@itconceptor.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            `De:${email}\n\nType: ${types}\n\nDescription:\n${description}`
        )}`;

        // Redirige l'utilisateur vers son client de messagerie avec le lien `mailto`
        window.location.href = mailtoLink;
    };

    const styles = ({
        contact: {
            display: isMobile || isTabletOrMobile ? "flex-wrap" : "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        text: {
            width: isMobile ? "100%" : isTabletOrMobile ? "90%" : "40%",
            margin: isMobile ? "0 0 20px" : isTabletOrMobile ? "0 auto 20px" : "",
        },
        para: {
            fontSize: "0.8em",
            color: darkMode ? '#fff' : '#000',
        },
        icons: {
            display: 'flex',
            width: isMobile || isTabletOrMobile ? '70%' : "",
            gap: isMobile || isTabletOrMobile ? '' : '20px',
            margin: isMobile || isTabletOrMobile ? '0 auto' : '',
            justifyContent: isMobile || isTabletOrMobile ? 'space-around' : '',
        },
        image: {
            width: "40%",
            margin: "5% 0",
        },
        form: {
            border: "1px solid #707070",
            borderRadius: "30px 30px 0 0",
            textAlign: "center",
            width: isMobile ? "100%" : isTabletOrMobile ? "90%" : "25%",
            margin: isTabletOrMobile ? " 0 auto" : "",
            backgroundColor: darkMode ? '#fff' : '',
        },
        formulaire: {
            padding: "5% 5% 0 5%",
        },
        formdiv: {
            display: "flex",
            width: "100%",
            margin: "0 0 5% 0",
            justifyContent: "space-between",
        },
        input1: {
            width: "100%",
            height: "4vh",
        },
        input2: {
            width: "45%",
            height: "4vh",
        },
        textarea: {
            minWidth: "100%",
            maxWidth: "100%",
            minHeight: "20vh",
            maxHeight: "20vh",
            padding: 0,
        },
        but: {
            textAlign: "left",
            marginBottom: "1.5vh",
        },
        button: {
            backgroundColor: "white",
            border: `1px solid #707070`,
            borderLeft: `2px solid #707070`,
            borderRight: `2px solid #707070`,
            boxShadow: "-5px 5px 3px #D6D6D6",
            fontSize: isMobile ? "0.8em" : isTabletOrMobile ? "0.9em" : "1em",
            margin: isMobile ? '10px 0 15px' : '20px 0',
        }
    })

    return (
        <div style={styles.contact}>
            <div style={styles.text}>
                <p style={styles.para}>Nous serions ravis de discuter de vos besoins et de vous montrer comment nous pouvons vous aider à atteindre vos objectifs. Contactez-nous dès aujourd'hui pour en savoir plus sur nos services et sur la façon dont nous pouvons vous aider à réussir dans le monde numérique.</p>
                <div style={styles.icons}>
                    <a href="https://wa.me/261388948046" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-whatsapp" style={{ fontSize: '2rem', color: '#25D366' }}></i>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61559802279212" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-facebook" style={{ fontSize: '2rem', color: '#3b5998' }}></i>
                    </a>
                    {/* <a href="#" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-instagram" style={{ fontSize: '2rem', color: '#C13584' }}></i>
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-twitter" style={{ fontSize: '2rem', color: '#1DA1F2' }}></i>
                    </a> */}
                </div>
            </div>
            <div style={styles.form}>
                <img style={styles.image} src='./images/logo-light2.png' alt="Logo ITCA" />
                <form style={styles.formulaire} onSubmit={handleSubmit}>
                    <div style={styles.formdiv}>
                        <input
                            style={styles.input1}
                            type="text"
                            placeholder="Sujet"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </div>
                    <div style={styles.formdiv}>
                        <input
                            style={styles.input2}
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            style={styles.input2}
                            type="text"
                            placeholder="Type"
                            value={types}
                            onChange={(e) => setTypes(e.target.value)}
                        />
                    </div>
                    <div style={styles.formdiv}>
                        <textarea
                            style={styles.textarea}
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <div style={styles.but}>
                        <button style={styles.button} type="submit">Send</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Contact;
