import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Footer = () => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const scrollTo = (id) => {
    const element = document.querySelector(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 2,
        behavior: 'smooth',
      });
    }
  };

  const styles = ({
    footer: {
      backgroundColor: "#DBA734",
      display: isMobile || isTabletOrMobile ? "flex-wrap" : "flex",
      alignItems: "center",
      marginTop: "30px",
      height: isMobile || isTabletOrMobile ? '' : '40vh',
    },
    img: {
      width: isMobile || isTabletOrMobile ? "100%" : "50%",
      // height: '40vh',
      padding: isTabletOrMobile ? '20% 0' : '',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      width: "60%",
      // height: "100%",
      // objectFit: 'cover',
    },
    txt: {
      display: isMobile || isTabletOrMobile ? "flex-wrap" : "flex",
      width: "48%",
      fontSize: '0.8rem',
      alignItems: 'flex-start',
      gap: '10%',
      paddingLeft: '10%',
      marginTop: isMobile || isTabletOrMobile ? '' : '',
    },
    text: {
      color: "white",
      lineHeight: 1.5,
      fontWeight: "bold",
      paddingBottom: isMobile || isTabletOrMobile ? "40px" : '',
    },
    para: {
      color: "white",
    },
    link: {
      textDecoration: "none",
      color: "white",
    }
  })

  return (
    <div style={styles.footer}>
      <div style={styles.img}>
        <img style={styles.image} src='./images/logo-light3.png' alt='logo ITCA' />
      </div>
      <div style={styles.txt}>
        <div style={styles.text}>
          <p style={styles.para}>Navigate</p>
          <p style={styles.para}><a style={styles.link} href='/' onClick={(e) => { e.preventDefault(); scrollTo('#home'); }}>Accueil</a></p>
          <p style={styles.para}><a style={styles.link} href='/' onClick={(e) => { e.preventDefault(); scrollTo('#about'); }}>About</a></p>
          <p style={styles.para}><a style={styles.link} href='/' onClick={(e) => { e.preventDefault(); scrollTo('#services'); }}>Services</a></p>
          <p style={styles.para}><a style={styles.link} href='/' onClick={(e) => { e.preventDefault(); scrollTo('#contact'); }}>Contact</a></p>
        </div>
        <div style={styles.text}>
          <p style={styles.para}>Service</p>
          <p style={styles.para}>Web Developer</p>
          <p style={styles.para}>Mobile Developer</p>
          <p style={styles.para}>Web SEO Manager</p>
          <p style={styles.para}>Computer Training</p>
          <p style={styles.para}>Designer</p>
        </div>
        <div style={styles.text}>
          <p style={styles.para}>Contact</p>
          <p style={styles.para}><a style={styles.link} href='mailto:contact@itconceptor.com' target='_blank'>contact@itconceptor.com</a></p>
          <p style={styles.para}><a style={styles.link} href='#' target='_blank'>www.itconceptor.com</a></p>
          <p style={styles.para}><a style={styles.link} href='#'>+261 38 89 480 46</a></p>
          <p style={styles.para}><a style={styles.link} href='#'>IT Conceptor Agency</a></p>
        </div>
      </div>

    </div>
  );
};

export default Footer;
